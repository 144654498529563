
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import { Container, Row, Col } from "react-bootstrap"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div>
        <main>{children}</main>
        <footer>

          <Container fluid style={{ padding: "2rem" }}>
            <Row>
              <Col>
                <div>Le Cabri Qui Flâne</div>
                <div>Estelle Le Quinio</div>
                <div><i>Accompagnatrice de moyenne montagne</i></div>
              </Col>
              <Col>
                <div className="float-right">
                  <div >lecabriquiflane@gmail.com</div>
                  <div className="text-right">0649266596</div>
                </div>
              </Col>
            </Row>
          </Container>

          <span>© {new Date().getFullYear()}, Built with Love by Brozeu</span>
        </footer>
      </div>
    </>
  )
}

export default Layout
