import React from "react"
import { Navbar, Nav, Button } from "react-bootstrap"
import Image from "./image"

const Header = ({ siteTitle }) => (
  <Navbar collapseOnSelect expand="lg" >
    <Image filename="goat.png" imgStyle={{ position: "relative" }} style={{ height: 50, width: 50, display: "inline-block" }} />
    < Navbar.Brand >
      <Nav.Link href="/" style={{ color: "white", paddingLeft: "1rem" }}>{siteTitle}</Nav.Link>
    </Navbar.Brand >
    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    <Navbar.Collapse id="responsive-navbar-nav">
      <Nav className="mr-auto" />
      <Nav >
        <Nav.Item style={{ padding: "0 1rem 0 1rem" }}><Nav.Link className="white-link" href="/">Accueil</Nav.Link></Nav.Item>
        <Nav.Item style={{ padding: "0 1rem 0 1rem" }}><Nav.Link className="white-link" href="/prestations/">Prestations</Nav.Link></Nav.Item>
        <Nav.Item style={{ padding: "0 1rem 0 1rem" }}><Nav.Link className="white-link" href="/tarifs/">Tarifs</Nav.Link></Nav.Item>
        <Nav.Item style={{ padding: "0 1rem 0 1rem" }}><Nav.Link className="white-link" href="/infos-pratiques/">Infos pratiques</Nav.Link></Nav.Item>
        <Nav.Item style={{ padding: "0 1rem 0 1rem" }}><Button className="white-link" href='/contact/' variant="secondary">Contact</Button></Nav.Item>
      </Nav>
    </Navbar.Collapse>
  </Navbar >
)

export default Header
